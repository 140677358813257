<script>
import Vue from 'vue'
import PurchaseOrderResource from '../../../../components/transactions/PurchaseOrderResource'
import { ResourceListField, ListFilterSelect } from '@sigmacloud/sigma-client/dist/components/core/resource_list'

export default Vue.extend({
    components: { PurchaseOrderResource },

    props: {
        instanceName: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            selectedResources: [],
            vendorResource: null,
            vendorAddressResource: null,
        }
    },

    computed: {
        selectedResource: ({ selectedResources }) => selectedResources[0],
        listFields() {
            return [
                new ResourceListField('Reference #', 'number'),
                new ResourceListField('Status', 'status'),
                new ResourceListField('Vendor', 'vendor.payee_name'),
                new ResourceListField('Description', 'description'),
                new ResourceListField('Amount', 'amount_decimal'),
            ]
        },
        listFilters() {
            return [
                new ListFilterSelect("Ignore POs I've Already Approved", 'user_specific', {
                    value: false,
                    editable: true,
                    choices: [true, false],
                }),
            ]
        },
        toggleSidebar: {
            get() {
                return !!this.selectedResource
            },
            set(newValue) {
                if (newValue === false) {
                    this.selectedResources = []
                    this.vendorResource = null
                    this.vendorAddressResource = null
                }
            },
        },
    },
    watch: {
        'selectedResource.attributes.vendor'() {
            if (this.selectedResource && this.selectedResource.attributes.vendor) {
                this.getVendorData()
            }
        },
    },
    methods: {
        async approveSelectedPurchaseOrder() {
            try {
                await this.selectedResource.approve()
                this.$refs.poList.list()
            } catch (e) {
                console.error(e)
                throw e
            }
        },
        async getVendorData() {
            this.vendorResource = await this.$resources.VendorResource.detail(this.selectedResource.attributes.vendor)
            if (this.vendorResource.attributes.address_selected) {
                this.vendorAddressResource = await this.$resources.AddressResource.detail(this.vendorResource.attributes.address_selected.id, {
                    resolveRelatedDeep: true,
                })
            }
        },
        isApprovable(resource) {
            if (!resource) return false

            if (['POSTED', 'CLOSED'].includes(resource.attributes.status)) {
                return false
            }
            return true
        },
    },
})
</script>

<template>
    <b-row v-page-title="'PO Approvals'">
        <b-col sm="12">
            <resource-list
                ref="poList"
                v-model="selectedResources"
                :resource-class="$resources.PurchaseOrderResource"
                :resolve-attributes="['batch.bank.currency.code']"
                :fields="listFields"
                :filters="listFilters"
                page-getter-method="approvable"
                title="Approve Purchase Orders"
                selectable>
                <template #value-status="{ resource }">
                    {{ resource.prettyStatus }}
                </template>
                <template #value-amount_decimal="{ resource }">
                    <resource-format
                        display-template="amount_decimal"
                        :resource="resource"
                        :currency="resource.get('batch.bank.currency.code')"
                        :append-text="' ' + resource.get('batch.bank.currency.code')" />
                </template>
            </resource-list>
        </b-col>
        <b-sidebar id="sidebar-po-approvals" v-model="toggleSidebar" width="75%" right shadow>
            <template #title>
                <b-button-toolbar class="mx-2">
                    <b-button-group>
                        <b-button @click="$refs.poList.selectPrevious">Previous</b-button>
                        <b-button @click="$refs.poList.selectNext">Next</b-button>
                    </b-button-group>
                    <b-button :disabled="!isApprovable(selectedResource)" class="ml-2" @click="$refs.purchaseOrderResource.openRejectPO()">Reject PO</b-button>
                    <b-button class="ml-2 float-right" :disabled="!isApprovable(selectedResource)" @click="approveSelectedPurchaseOrder"> Approve </b-button>
                </b-button-toolbar>
            </template>
            <hr />
            <div v-if="selectedResource" class="po-approval-details">
                <purchase-order-resource
                    :id="selectedResource.attributes.id"
                    ref="purchaseOrderResource"
                    resource-class-name="PurchaseOrderResource"
                    instance-name="PurchaseOrderResource"
                    display-as-modal
                    readonly />
            </div>
        </b-sidebar>
    </b-row>
</template>

<style lang="scss" scoped>
.status-tooltip-text {
    text-align: left;
}
</style>
